$rhap_theme-color: var(
  --dark-theme-color
) !default; // Color of all buttons and volume/progress indicators
$rhap_background-color: var(
  --lighter-theme-color
) !default; // Color of the player background
$rhap_bar-color: var(
  --light-theme-color
) !default; // Color of volume and progress bar
$rhap_time-color: var(
  --dark-theme-color
) !default; // Font color of current time and duration
$rhap_font-family: inherit !default; // Font family of current time and duration

@import "react-h5-audio-player/src/styles.scss";

.rhap_progress-bar-show-download {
  background-color: rgba(var(--dark-theme-rgb), 0.5);
}
